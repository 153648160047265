//
//
//
//
//
//
//
//

import setInitialTheme from '~/mixins/setInitialTheme'

export default {
  mixins: [setInitialTheme],
}
