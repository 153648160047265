//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: {
      type: Object,
      default: () => {
        return { statusCode: 404 }
      },
    },
  },
  data() {
    return {
      htmlCode: `{
      - {
        "id": "act_fd3h6evhpn3uxdoqbuu3lqnqbm",
        "type": "Refund",
        "processed_on": "2018-01-20T10:30:48Z",
        "amount": 1000,
        "approved": true,
        "response_code": "10000",
        "response_summary": "Approved"
        },
      - {
        "id": "act_gefycn3jcvuupboxfmqrhk2aym",
        "type": "Capture",
        "processed_on": "2018-01-17T10:30:48Z",
        "amount": 6540,
        "approved": true,
        "response_code": "10000",
        "response_summary": "Approved",
        "processing": true,
        "acquirer_transaction_id": "00745244079"
      },
      - {
        "metadata": "Form3 Financial Cloud",
        "id": "act_gefycn3jcvuupboxfmqrhk2aym",
        "type": "Capture",
        "processed_on": "2018-01-17T10:30:48Z",
        "amount": 6540,
        "approved": true,
        "response_code": "10000",
        "response_summary": "Approved",
        "processing": true
      }`,
    }
  },
  head() {
    return {
      title: 'Form3 API Docs',
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex' }],
    }
  },
}
