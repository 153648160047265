//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import matchUrlFromStore from '@/components/global/mixins/matchUrlFromStore'
import autoScrollToMatchedUrl, {
  SCROLL_STRATEGY,
} from '@/components/global/mixins/autoScrollToMatchedUrl'
import MobileSidebarFile from './MobileSidebarFile'

export default {
  components: {
    MobileSidebarFile,
  },
  mixins: [matchUrlFromStore, autoScrollToMatchedUrl],
  props: MobileSidebarFile.props,
  data() {
    return {
      scrollStrategy: SCROLL_STRATEGY.scrollIfNeeded,
    }
  },
}
