export const state = () => ({
  items: [],
  isMobileSidebarOpened: false,
  isMobileSidebarRendered: false,
})

export const mutations = {
  set(state, items) {
    state.items = items
  },
  openMobileSidebar(state) {
    state.isMobileSidebarOpened = true
  },
  closeMobileSidebar(state) {
    state.isMobileSidebarOpened = false
  },
  mountMobileSidebar(state) {
    state.isMobileSidebarRendered = true
  },
  unmountMobileSidebar(state) {
    state.isMobileSidebarRendered = false
  },
}

export const actions = {
  unmountMobileSidebar({ commit }) {
    commit('closeMobileSidebar')
    commit('unmountMobileSidebar')
  },
}
