import flattenDeep from 'lodash.flattendeep'
import { flatten } from 'flat'
import {
  isActiveDocument,
  reconstructMenuTreeFromFlatMap,
  processDirectory,
} from '../utils/pages-utils'

export const isMetaPath = (path = '') => /(^|\/)_meta\//.test(path)

const getSidebarNavigationContent = ($context, sidebarFileName) => {
  return $context.$content(sidebarFileName).fetch()
}

const getDocuments = async (
  $context,
  {
    sidebarNavigationContent,
    sidebarParentKey,
    sidebarChildKey,
    removeTocItemsTextAfterComma,
  }
) => {
  const pathRootString = `${sidebarParentKey}/${sidebarChildKey}`

  const items = sidebarNavigationContent[sidebarParentKey]

  const scheme = items[sidebarChildKey]

  const flatItems = flatten(scheme, {
    delimiter: '/',
    safe: true,
  })

  const flatFileMap = await Promise.all(
    Object.entries(flatItems)
      .filter(([path]) => !isMetaPath(path))
      .map(([path, children]) =>
        processDirectory({
          $context,
          $content: $context.$content,
          files: children,
          path: path.replace(/\/children$/, '').trim(),
          rootDir: `/${pathRootString}`,
          flatItems,
        })
      )
  )

  let menuTree = reconstructMenuTreeFromFlatMap(flatFileMap, pathRootString)

  if (removeTocItemsTextAfterComma) {
    menuTree = removeTocItemsTextAfterComma(menuTree)
  }

  $context.store.commit('sidebar/set', menuTree)

  const getDocsFromMenuTree = (menuTree) => {
    if (menuTree.children) {
      return menuTree.children.map(getDocsFromMenuTree)
    }

    return [menuTree]
  }

  return flattenDeep(menuTree.map(getDocsFromMenuTree))
}

// fallback, in case URL is not pointing to a document
const getActiveDocument = ($context, docs) => {
  const routePath = decodeURIComponent($context.route.path)
  const activeDocumentWithoutBody = docs.find((doc) =>
    isActiveDocument(doc, routePath)
  )

  return activeDocumentWithoutBody
}

const getRedirectToSectionUrl = (docs, activeDocument) => {
  if (!activeDocument) {
    return `${docs[0].path}`
  }
  return null
}

export default function ({
  title,
  sidebarFileName,
  sidebarParentKey,
  sidebarChildKey,
  removeTocItemsTextAfterComma,
}) {
  return async function ($context) {
    if ($context.route.params.routeUpdatedByScroll) {
      return
    }

    const schemeUniqueName = `${sidebarParentKey}/${sidebarChildKey}`

    const currentScheme = {
      name: sidebarChildKey,
      uniqueName: schemeUniqueName,
      title,
    }

    if (
      $context.store.state.schemes.currentScheme?.uniqueName !==
        schemeUniqueName ||
      $context.store.state.docs.items.length === 0
    ) {
      if (process.client) {
        $context.store.commit('docs/resetAllDocuments')
      }
      $context.store.commit('schemes/setCurrentScheme', currentScheme)
    }

    if (process.client) {
      $context.store.commit('docs/resetActiveSectionUrl')
    }
    $context.store.commit('docs/redirectToSectionUrl', null)

    const sidebarNavigationContent = await getSidebarNavigationContent(
      $context,
      sidebarFileName
    )

    const docs = await getDocuments($context, {
      sidebarNavigationContent,
      sidebarParentKey,
      sidebarChildKey,
      removeTocItemsTextAfterComma,
    })

    if (
      $context.store.state.schemes.currentScheme?.uniqueName !==
        schemeUniqueName ||
      $context.store.state.docs.items.length === 0
    ) {
      $context.store.commit('docs/set', docs)
    }

    const activeDocument = await getActiveDocument($context, docs)

    const redirectToSectionUrl = getRedirectToSectionUrl(docs, activeDocument)
    $context.store.commit('docs/redirectToSectionUrl', redirectToSectionUrl)
  }
}
