import Cookies from 'js-cookie'
import isEqual from 'lodash.isequal'

export const cookieName = 'consent-cookie'

export const cookieCategory = {
  analytics: 'analytics',
  functionality: 'functionality',
}

export const defaultCookieValue = {
  [cookieCategory.analytics]: false,
  [cookieCategory.functionality]: false,
}

export const allowAllCookieValue = {
  [cookieCategory.analytics]: true,
  [cookieCategory.functionality]: true,
}

export const userHasCookieSettings = () => {
  return localStorage.getItem(cookieName) !== null
}

export const userHasDefaultCookieSettings = () => {
  return isEqual(defaultCookieValue, getUserCookieSettings())
}

export const getUserCookieSettings = () => {
  const defaultValue = { ...defaultCookieValue }

  try {
    return JSON.parse(localStorage.getItem(cookieName)) || defaultValue
  } catch (err) {
    return defaultValue
  }
}

export const saveUserCookieSettings = (cookieSettings) => {
  localStorage.setItem(cookieName, JSON.stringify(cookieSettings))
}

export const deleteAllCookies = () => {
  Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie))
}
