export const state = () => ({
  currentScheme: null,
  availableSchemes: [],
  schemesByName: {},
})

export const mutations = {
  setCurrentScheme(state, currentScheme) {
    state.currentScheme = currentScheme
  },
  setAvailableSchemes(state, availableSchemes) {
    state.availableSchemes = availableSchemes
    state.schemesByName = availableSchemes?.reduce((acc, val) => {
      acc[val?.name] = val
      return acc
    }, {})
  },
}
