export const SCROLL_STRATEGY = {
  default: 'DEFAULT',
  scrollIfNeeded: 'SCROLL_IF_NEEDED',
}

const MAX_RETRY_COUNT = 10

const mixin = {
  data() {
    return {
      timeoutRef: null,
      timeoutCounter: 0,
      scrollStrategy: 'default',
    }
  },
  watch: {
    isLinkedToAndExactMatch(newVal) {
      if (newVal) {
        this.scrollFullyMatchedElementIntoView()
      }
    },
  },
  mounted() {
    if (this.isLinkedToAndExactMatch) {
      this.scrollFullyMatchedElementIntoView()
    }
  },
  destroyed() {
    clearTimeout(this.timeoutRef)
  },
  methods: {
    scrollFullyMatchedElementIntoView() {
      clearTimeout(this.timeoutRef)
      this.timeoutRef = setTimeout(() => {
        // url is matching & we are not scrolling...
        if (this.isLinkedToAndExactMatch) {
          if (this.$el && this.$el.scrollIntoView) {
            if (
              this.scrollStrategy === SCROLL_STRATEGY.scrollIfNeeded &&
              this.$el.scrollIntoViewIfNeeded
            ) {
              this.$el.scrollIntoViewIfNeeded(true)
            } else {
              this.$el.scrollIntoView(true)
            }
            this.timeoutCounter = 0
          } else {
            this.timeoutCounter++

            if (this.timeoutCounter >= MAX_RETRY_COUNT) {
              // eslint-disable-next-line no-console
              console.error('MAX_RETRY_COUNT reached')
            }

            this.scrollFullyMatchedElementIntoView()
          }
        }
      })
    },
  },
}

export default mixin
