import { render, staticRenderFns } from "./index.vue?vue&type=template&id=79af48c6&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=79af48c6&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79af48c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LightLogo: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/global/Logo/LightLogo.vue').default})
