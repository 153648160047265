//
//
//
//
//
//
//
//
//
//
//
//

import Link from './Link.vue'
export default {
  name: 'SidebarToggle',
  components: { Link },

  computed: {},
}
