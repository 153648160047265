//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import matchUrlFromStore from '@/components/global/mixins/matchUrlFromStore'
import autoScrollToMatchedUrl, {
  SCROLL_STRATEGY,
} from '@/components/global/mixins/autoScrollToMatchedUrl'
import sidebarMixin from '~/mixins/sidebarMixin'
export default {
  name: 'TocLinked',
  mixins: [matchUrlFromStore, autoScrollToMatchedUrl, sidebarMixin],
  props: {
    isParentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      scrollStrategy: SCROLL_STRATEGY.scrollIfNeeded,
    }
  },
  computed: {
    isActive() {
      return this.isMatching
    },
    tabIndex() {
      return this.isParentActive ? 0 : -1
    },
  },
  methods: {
    ...mapActions({
      setCurrentlyActiveSectionUrl: 'docs/setCurrentlyActiveSectionUrl',
      forceScrollToUrl: 'docs/forceScrollToUrl',
    }),
    clickedLink() {
      if (this.fullLinkedTo) {
        this.setCurrentlyActiveSectionUrl(this.fullLinkedTo)
        this.forceScrollToUrl()
      }
    },
  },
}
