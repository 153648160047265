//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import TocLinked from './TocLinked'
import sidebarMixin from '~/mixins/sidebarMixin'
export default {
  name: 'SidebarFile',
  components: { TocLinked },
  mixins: [sidebarMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    level: {
      type: Number,
      required: false,
      default: () => 0,
    },
    isParentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      childrenVisible: this.isActive,
    }
  },

  computed: {
    hasArrow() {
      return Boolean(this.item.toc?.length)
    },
    tabIndex() {
      return this.isParentActive ? 0 : -1
    },
  },
  watch: {
    isActive(v) {
      this.childrenVisible = v
    },
  },
  methods: {
    ...mapActions({
      setCurrentlyActiveSectionUrl: 'docs/setCurrentlyActiveSectionUrl',
      forceScrollToUrl: 'docs/forceScrollToUrl',
    }),
    clickedLink() {
      if (this.item.path) {
        this.setCurrentlyActiveSectionUrl(`${this.item.path}`)
        this.forceScrollToUrl()
      }
    },
  },
}
