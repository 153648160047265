import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=f419e14a&"
import script from "./landing.vue?vue&type=script&lang=js&"
export * from "./landing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/Menu/index.vue').default,CookiesBanner: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/Cookies/Banner/index.vue').default,CookiesModal: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/Cookies/Modal/index.vue').default})
