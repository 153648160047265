//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
import { getUserCookieSettings, saveUserCookieSettings } from '~/utils/cookie'

export default {
  data() {
    return {
      cookieSettings: [],
      necessary: ['necessary'],
      cookieMap: {
        analytics: false,
        functionality: false,
      },
    }
  },
  computed: {
    ...mapState('behaviour', {
      isCookieModalVisible: 'isCookieModalVisible',
    }),
  },
  mounted() {
    const cookieSettings = this.getCookies()
    this.cookieSettings = Object.entries(cookieSettings).map(
      ([key, val]) => val && key
    )
  },
  methods: {
    ...mapMutations('behaviour', {
      hideCookieModal: 'hideCookieModal',
    }),
    getCookies() {
      return getUserCookieSettings()
    },
    saveHandler() {
      Object.keys(this.cookieMap).forEach((cookie) => {
        this.cookieMap[cookie] = this.cookieSettings.includes(cookie)
      })
      saveUserCookieSettings(this.cookieMap)
      window.location.reload()
    },
  },
}
