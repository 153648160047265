//
//
//
//
//
//
//
//
//

import MobileSidebarFileLinked from './SidebarFile/MobileSidebarFileLinked'
import MobileSidebarDirLinked from './SidebarDir/MobileSidebarDirLinked'

export default {
  name: 'MobileSidebarElement',
  components: {
    MobileSidebarFileLinked,
    MobileSidebarDirLinked,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    level: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  computed: {
    component() {
      return this.item.children
        ? MobileSidebarDirLinked
        : MobileSidebarFileLinked
    },
  },
}
