//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import MobileSidebar from './'

export default {
  components: { MobileSidebar },
  computed: {
    items() {
      return this.$store.state.sidebar.items
    },
    isMobileSidebarOpened() {
      return this.$store.state.sidebar.isMobileSidebarOpened
    },
  },
  watch: {
    '$route.path'() {
      this.closeMobileSidebar()
    },
  },
  methods: {
    ...mapMutations({
      closeMobileSidebar: 'sidebar/closeMobileSidebar',
    }),
  },
}
