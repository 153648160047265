//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
  name: 'Footer',
  props: {
    pageHasMaxWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
  methods: {
    ...mapMutations('behaviour', {
      showCookieBanner: 'showCookieBanner',
    }),
  },
}
