export const state = () => ({
  messages: [],
})

export const mutations = {
  add(state, message) {
    const timestamp = Date.now()
    state.messages.push({
      ...message,
      timestamp,
    })
  },
  remove(state, timestamp) {
    state.messages = state.messages.filter((message) => {
      return message.timestamp !== timestamp
    })
  },
}

export const getters = {
  all(state) {
    return state.messages
  },
}
