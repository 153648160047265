//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MobileTocLinked from './MobileTocLinked'

export default {
  name: 'MobileSidebarFile',
  components: { MobileTocLinked },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    level: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  computed: {
    childrenAreVisible() {
      return this.item.toc && this.isActive
    },
    hasArrow() {
      return this.item.toc?.length
    },
  },
}
