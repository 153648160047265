//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import matchUrlFromStore from '@/components/global/mixins/matchUrlFromStore'
import autoScrollToMatchedUrl, {
  SCROLL_STRATEGY,
} from '@/components/global/mixins/autoScrollToMatchedUrl'
import SidebarFile from './'
import sidebarMixin from '~/mixins/sidebarMixin'
export default {
  name: 'SidebarFileLinked',
  components: {
    SidebarFile,
  },
  mixins: [matchUrlFromStore, autoScrollToMatchedUrl, sidebarMixin],
  props: SidebarFile.props,
  data() {
    return {
      scrollStrategy: SCROLL_STRATEGY.scrollIfNeeded,
    }
  },
  computed: {
    isItemActive() {
      return this.isMatching
    },
  },
}
