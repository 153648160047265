//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    inputId: {
      type: String,
      default: 'search-trigger-mobile',
      required: false,
    },
  },
  computed: {
    isSearchModalOpened() {
      return this.$store.state.search.isSearchModalOpened
    },
  },
  methods: {
    handleFocus() {
      this.$refs.input.blur()
      this.$store.commit('search/openSearchModal')
    },
  },
}
