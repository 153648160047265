var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"searchModalContainer",staticClass:"search-modal",class:{ opened: _vm.isOpened },on:{"click":_vm.closeSearchModal,"focusout":function (e) { return _vm.handleFocusOut(e); }}},[_vm._m(0),_vm._v(" "),(_vm.searchIsDisabled)?_c('div',{staticClass:"p-4 dark:bg-darkmode-soft-white bg-soft-white rounded-xl"},[_vm._v("\n    Search is disabled in preview mode\n  ")]):_c('div',{staticClass:"h-full md:h-auto",on:{"click":function($event){$event.stopPropagation();},"keydown":_vm.handleKeydown}},[_c('ais-instant-search',{attrs:{"index-name":_vm.indexName,"search-client":_vm.searchClient,"class-names":{ 'ais-InstantSearch': 'wrapper' }}},[_c('ais-configure',{attrs:{"facets":_vm.facets,"optionalFilters":_vm.optionalFilters}},[_c('ais-autocomplete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var indices = ref.indices;
var currentRefinement = ref.currentRefinement;
return _c('div',{staticClass:"flex flex-col h-full md:block md:h-auto",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.handleEscape($event)}}},[_c('ais-search-box',{ref:"searchBox",attrs:{"class-names":{
                'ais-InstantSearch': 'input-wrapper',
                'ais-SearchBox-input': 'input search-input',
              },"placeholder":"Product, feature, page...","reset-title":"clear"},on:{"hook:mounted":_vm.timedOutFocusInput}}),_vm._v(" "),(currentRefinement)?_c('div',{staticClass:"results_wrapper"},[(indices.length && indices[0].hits.length)?_c('ul',{staticClass:"result_list"},_vm._l((indices[0].hits),function(hit){return _c('li',{key:hit.objectID,staticClass:"result_wrapper",attrs:{"tabindex":"0"},on:{"click":_vm.closeSearchModal}},[_c('NuxtLink',{staticClass:"result_container",attrs:{"to":hit.url,"title":hit.url,"tabindex":"-1"}},[_c('div',{staticClass:"result_icon"},[_c('span',{staticClass:"dark:text-darkmode-gray text-gray",class:_vm.getIconFromCategory(hit.category)})]),_vm._v(" "),_c('div',{staticClass:"result_content"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight': 'result_title',
                          'ais-Highlight-highlighted':
                            'result_title--highlighted',
                        },"hit":hit,"attribute":"title"}}),_vm._v(" "),_c('Breadcrumbs',{key:hit.url,staticClass:"result_breadcrumbs",attrs:{"url":hit.url,"last-element-class-name":"result_breadcrumbs--last-item"}}),_vm._v(" "),_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight': 'result_text',
                          'ais-Highlight-highlighted':
                            'result_text--highlighted',
                        },"hit":hit,"attribute":"text"}}),_vm._v(" "),_c('div',[_c('Tag',{staticClass:"mt-2",attrs:{"color":_vm.getSchemeTagColor(hit.scheme)}},[_c('ais-highlight',{attrs:{"class-names":{
                              'ais-Highlight': 'result_scheme',
                              'ais-Highlight-highlighted':
                                'result_scheme--highlighted',
                            },"hit":hit,"attribute":"scheme"}})],1)],1)],1)])],1)}),0):_c('p',{staticClass:"results_empty text-navy-dark dark:text-darkmode-navy-dark"},[_vm._v("\n                No results for \""),_c('strong',[_vm._v(_vm._s(currentRefinement))]),_vm._v("\"\n              ")])]):_vm._e()],1)}}])})],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"search-modal__mobile-close-trigger",attrs:{"title":"close"}},[_c('span',{staticClass:"form3-close-icon"})])}]

export { render, staticRenderFns }