import { mapGetters } from 'vuex'

import { removeLastSlashFromUrl, isSubUrl } from '@/utils'

const mixin = {
  provide() {
    return {
      parentUrl: this.fullLinkedTo,
    }
  },
  inject: {
    parentUrl: { default: '' },
  },
  props: {
    linkedTo: {
      type: String,
      required: false,
      default: '',
    },
    isHashLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      activeSectionUrl: 'docs/activeSectionUrl',
    }),
    fullLinkedToEncoded() {
      if (this.fullLinkedTo) {
        return encodeURI(this.fullLinkedTo)
      }

      return this.fullLinkedTo
    },
    fullLinkedTo() {
      if (!this.parentUrl && !this.linkedTo) {
        return ''
      }

      if (!this.parentUrl && this.linkedTo) {
        return this.linkedTo
      }

      if (this.parentUrl && !this.linkedTo) {
        return this.parentUrl
      }

      return `${this.parentUrl}${this.separator}${this.linkedTo}`
    },
    separator() {
      if (!this.isHashLink) {
        return '/'
      }

      if (!this.parentUrl.includes('#')) {
        return '#'
      }

      return '.'
    },
    isMatching() {
      if (!this.activeSectionUrl || !this.fullLinkedToEncoded) {
        return false
      }

      const currentRoute = `${removeLastSlashFromUrl(this.activeSectionUrl)}`

      const result = isSubUrl(this.fullLinkedToEncoded, currentRoute)

      return result
    },
    isExactMatch() {
      return this.isExactlyMatchingPath()
    },
    isLinkedToAndExactMatch() {
      if (!this.linkedTo) {
        return false
      }

      return this.isExactMatch
    },
  },
  watch: {
    isMatching() {
      this.$emit('matching-url')
    },
    isExactMatch() {
      this.$emit('exactly-matching-url')
    },
  },
  mounted() {
    if (this.isMatching) {
      this.$emit('matching-url-at-mount')
      this.$emit('matching-url')
    }

    if (this.isExact) {
      this.$emit('exactly-matching-url-at-mount')
      this.$emit('exactly-matching-url')
    }
  },
  methods: {
    isExactlyMatchingPath() {
      if (!this.activeSectionUrl) {
        return
      }
      const currentRoute = `${removeLastSlashFromUrl(this.activeSectionUrl)}`

      if (!currentRoute) {
        return false
      }

      if (currentRoute === this.fullLinkedToEncoded) {
        return true
      }

      return false
    },
  },
}

export default mixin
