//
//
//
//
//
//
//
//
//
//
//

import SidebarDirLinked from './SidebarDir/Linked'
import SidebarFileLinked from './SidebarFile/Linked'

export default {
  name: 'SidebarElement',
  components: {
    SidebarDirLinked,
    SidebarFileLinked,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    level: {
      type: Number,
      required: false,
      default: () => 0,
    },
    isParentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    component() {
      return this.item.children ? SidebarDirLinked : SidebarFileLinked
    },
  },
}
