//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export const BUTTON_THEME = {
  default: 'default',
  light: 'light',
  dark: 'dark',
  white: 'white',
  teal: 'teal',
  alternate: 'alternate',
  blue: 'blue',
  lightTeal: 'lightTeal',
}
export default {
  props: {
    theme: {
      type: String,
      required: false,
      default: BUTTON_THEME.default,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
