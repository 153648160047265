//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import setInitialTheme from '~/mixins/setInitialTheme'

export default {
  name: 'DarkMode',
  mixins: [setInitialTheme],
  data() {
    return {
      matchMediaRef: null,
    }
  },
  beforeMount() {
    this.watchForThemeChange()
  },
  destroyed() {
    if (this.matchMediaRef) {
      this.matchMediaRef.removeEventListener(
        'change',
        this.systemColourSchemeChanged
      )
    }
  },
  methods: {
    watchForThemeChange() {
      this.matchMediaRef = window.matchMedia('(prefers-color-scheme: dark)')
      this.matchMediaRef.addEventListener(
        'change',
        this.systemColourSchemeChanged
      )
    },
    systemColourSchemeChanged(event) {
      const theme = event.matches ? 'dark' : 'light'
      this.setThemeTo(theme)
    },
  },
}
