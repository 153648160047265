export const textToHyperlink = (str = '') => {
  if (!str) {
    return ''
  }
  if (typeof str !== 'string') {
    return str
  }
  return str.toLowerCase().replace(/\s+/g, '-')
}

export const removeLastSlashFromUrl = (url = '') => {
  if (!url) {
    return ''
  }
  if (typeof url !== 'string') {
    return url
  }
  if (url[url.length - 1] === '/') {
    return url.substr(0, url.length - 1)
  }

  return url
}

export const areUrlsMatching = (url = '', comparison = '') => {
  if (!url || !comparison) {
    return false
  }
  if (typeof url !== 'string' || typeof comparison !== 'string') {
    return false
  }
  const noLastSlashUrl = removeLastSlashFromUrl(url)
  const urlArr = noLastSlashUrl
    .replace(/^\//, '')
    .replace(/^api\//, '')
    .split('/')

  const comparisonArr = comparison
    .replace(/^\//, '')
    .replace(/^api\//, '')
    .split('/')

  const minLength = Math.min(urlArr.length, comparisonArr.length)

  for (let i = 0; i < minLength; i++) {
    if (urlArr[i] !== comparisonArr[i]) {
      return false
    }
  }

  return true
}

// fullUrl.indexOf(componentUrl) === 0
// fullUrl - componentUrl = '/' || '.' || '#'
export const isSubUrl = (componentUrl, fullUrl) => {
  if (componentUrl === fullUrl) {
    return true
  }

  if (fullUrl.indexOf(componentUrl) !== 0) {
    return false
  }

  const separators = ['/', '#', '.']
  const diff = fullUrl.replace(componentUrl, '')
  const diffIsAfterASeparator = separators.some(
    (separator) => separator === diff[0]
  )

  return diffIsAfterASeparator
}

export const safelyReplaceQuotes = (data) => {
  if (!data) {
    return ''
  }

  return data.replace(/'/g, "\\'").replace(/"/g, "'")
}

export const set = (destination = {}, path = '', value) => {
  const pathParts = path.split(/\./g)

  let currentObject = destination

  pathParts.forEach((part, i) => {
    if (i < pathParts.length - 1) {
      currentObject[part] = currentObject[part] || {}
      currentObject = currentObject[part]
    } else {
      currentObject[part] = value
    }
  })
}

export const setAndMergeObjects = (destination = {}, path = '', value) => {
  const pathParts = path.split(/\./g)

  let currentObject = destination

  pathParts.forEach((part, i) => {
    if (i < pathParts.length - 1) {
      currentObject[part] = currentObject[part] || {}
      currentObject = currentObject[part]
    } else {
      currentObject[part] = {
        ...currentObject[part],
        ...value,
      }
    }
  })
}

export const extractScheme = (path) => {
  if (!path) {
    return path
  }

  return path.replace(/^\/api\/schemes\/(.*?)\/.*$/, '$1')
}

export const extractDocumentPath = (path) => {
  if (!path) {
    return path
  }

  return path.replace(/^\/api\/schemes\/[^/]+\/([^/]+).*$/, '$1')
}

export const extractSectionSlug = (path) => {
  if (!path) {
    return path
  }

  return path.replace(/^\/api\/schemes\/[^/]+\/[^/]+\/([^/]+).*$/, '$1')
}
