//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    url: {
      type: String,
      required: true,
      default: '',
    },
    lastElementClassName: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    items() {
      return (
        this.url
          // remove any leading separators
          .replace(/^[/#.]/, '')
          // remove any trailing separators
          .replace(/[/#.]$/, '')
          // replace custom separators with slashes
          .replace(/[#.]/g, '/')
          // split by the only separator
          .split('/')
          // filter out unwanted items, such as "schemes"
          .filter((item) => {
            if (item === 'schemes') {
              return false
            }

            return true
          })
      )
    },
  },
  methods: {
    isLastItem(index) {
      return index === this.items.length - 1
    },
    getKey(index) {
      return [
        ...this.items.slice(0, index),
        `@${this.items[index]}@`,
        ...this.items.slice(index + 1),
      ].join('/')
    },
  },
}
