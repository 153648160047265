//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DarkMode from '../../DarkMode'
import DesktopMenuItem from './DesktopMenuItem.vue'

export default {
  components: { DesktopMenuItem, DarkMode },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    pageHasSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.$refs.menuContainer.focus()
  },
  methods: {
    skipToMainContent(event) {
      if (event.key === 'Enter') {
        const mainContent = document.querySelector('.main-content')

        if (mainContent) {
          mainContent.focus()
        }
      }
    },
  },
}
