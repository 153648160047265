//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
import GenericButtonWithState, {
  STATES,
} from '~/components/global/GenericButton/WithState'
import {
  allowAllCookieValue,
  deleteAllCookies,
  saveUserCookieSettings,
  userHasCookieSettings,
} from '~/utils/cookie'

export default {
  name: 'CookiesBanner',
  components: {
    GenericButtonWithState,
  },
  data() {
    return {
      showModal: false,
      buttonState: STATES.default,
      timeout: null,
    }
  },
  computed: {
    ...mapState('behaviour', {
      showBanner: 'isCookieBannerVisible',
      cookieBannerOpenedOnDemand: 'cookieBannerOpenedOnDemand',
    }),
  },
  mounted() {
    if (userHasCookieSettings()) {
      this.hideCookieBanner()
    } else {
      this.showCookieBanner()
    }
  },
  methods: {
    ...mapMutations('behaviour', {
      showCookieModal: 'showCookieModal',
      hideCookieBanner: 'hideCookieBanner',
      showCookieBanner: 'showCookieBanner',
    }),
    clickHandler() {
      this.buttonState = STATES.loading

      // delete previously saved cookies
      deleteAllCookies()

      try {
        // store this cookie pref
        saveUserCookieSettings(allowAllCookieValue)
      } catch (err) {
        this.buttonState = STATES.error
        return
      }

      this.timeout = setTimeout(() => {
        this.buttonState = STATES.success
        // refresh the page in order for the tracking scripts removal to take effect
        window.location.reload()
      }, 1000)
    },
  },
}
