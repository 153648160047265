export default {
  data() {
    return {
      tailwindTheme: 'light',
      initialThemeSet: false,
    }
  },
  beforeMount() {
    this.setInitialTheme()
  },
  methods: {
    setInitialTheme() {
      let theme
      if (
        window?.matchMedia &&
        window?.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        theme = 'dark'
      } else {
        theme = 'light'
      }

      const htmlNode = document.querySelectorAll('html')[0]
      const initialThemeAlreadySet =
        htmlNode.classList.contains('dark') ||
        htmlNode.classList.contains('light')

      if (!initialThemeAlreadySet) {
        this.setThemeTo(theme)
      }

      this.initialThemeSet = true
    },
    setThemeTo(theme) {
      this.$store.commit('theme/setTheme', theme)
      const allowedThemes = ['light', 'dark']

      if (allowedThemes.includes(theme)) {
        const htmlNode = document.querySelectorAll('html')[0]
        this.tailwindTheme = theme
        if (theme === 'dark') {
          // also set in local storage
          htmlNode.classList.add('dark')
          htmlNode.classList.remove('light')
        } else {
          htmlNode.classList.add('light')
          htmlNode.classList.remove('dark')
        }
        this.tailwindTheme = theme
      }
    },
  },
}
