//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import SidebarElement from './SidebarElement'
import SidebarToggle from './SidebarToggle'

export default {
  name: 'Sidebar',
  components: {
    SidebarElement,
    SidebarToggle,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    activeItemTitle: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      sidebarSectionsOrder: [
        undefined,
        'platform',
        'transactions',
        'additional services',
      ],
    }
  },

  computed: {
    groupedElements() {
      const groups = {}

      this.items.forEach((i) => {
        const group = groups[i.tag]

        if (!group) {
          groups[i.tag] = [i]
        } else {
          groups[i.tag] = [...group, i]
        }
      })

      return groups
    },

    isReleasesPage() {
      return this.$route.name.match(/releases-(production|staging)-all/)
    },

    sortedGroups() {
      const groups = {}

      this.sidebarSectionsOrder.forEach((e) => {
        const elements = this.groupedElements[e]

        if (elements) {
          groups[e] = elements
        }
      })

      return groups
    },
  },
  methods: {
    ...mapMutations({
      openMobileSidebar: 'sidebar/openMobileSidebar',
    }),
    filtered(set, filteringTerm) {
      return set.filter((i) => i.tag === filteringTerm)
    },
    handleSidebarClose() {
      this.$emit('onMobileSidebarClose')
    },
  },
}
