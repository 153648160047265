export default (route, schemes) => {
  return [
    {
      title: 'API Reference',
      isActive: route === 'api-schemes-scheme-all',
      isTopLevel: true,
      children: schemes
        .filter(
          (scheme) => scheme.name !== 'tutorials' && scheme.name !== 'staging'
        )
        .map((scheme) => ({
          url: `/api/schemes/${scheme.name}/`,
          title: scheme.title,
        })),
    },
    {
      title: 'Tutorials',
      isActive: route === 'api-tutorials-all',
      isTopLevel: true,
      url: '/api/tutorials',
    },
    {
      title: 'Staging Environment',
      isActive: route === 'api-staging-all',
      isTopLevel: true,
      url: '/api/staging',
    },
    {
      title: 'Resources',
      isActive: route === 'releases',
      isTopLevel: true,
      children: [
        {
          title: 'Swagger File (Ireland Region)',
          url: '/assets/swagger/form3-swagger-ireland.yaml',
          isExternal: true,
        },
        {
          title: 'Swagger File (All Other Regions)',
          url: '/assets/swagger/form3-swagger.yaml',
          isExternal: true,
        },
        {
          title: 'Postman Collection',
          url: 'https://documenter.getpostman.com/view/5561717/TWDTNzaD',
          isExternal: true,
        },
        {
          title: 'Release Log',
          url: '/releases',
        },
        {
          title: 'API Status',
          url: 'https://status.form3.tech/',
          isExternal: true,
        },
      ],
    },
  ]
}
