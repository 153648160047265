//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MobileSidebarDir',
  components: {
    MobileSidebarElement: () => import('../MobileSidebarElement.vue'),
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isExpanded: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    level: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      internalIsExpanded: false,
    }
  },
  computed: {
    childrenAreVisible() {
      return this.isExpanded || this.internalIsExpanded
    },
  },
  methods: {
    toggleChildrenVisibility(e) {
      this.internalIsExpanded = !this.internalIsExpanded
    },
  },
}
