import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueTippy, {
  interactive: true,
  theme: 'light-border',
  animateFill: false,
  arrow: true,
  arrowType: 'round',
  placement: 'top',
})
Vue.component('tippy', TippyComponent)
