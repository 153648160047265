export const state = () => ({
  isSearchModalOpened: false,
})

export const mutations = {
  openSearchModal(state) {
    state.isSearchModalOpened = true
  },
  closeSearchModal(state) {
    state.isSearchModalOpened = false
  },
}

export const actions = {
  unmountMobileSidebar({ commit }) {
    commit('closeMobileSidebar')
    commit('unmountMobileSidebar')
  },
}
