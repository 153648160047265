//
//
//
//

import { mapGetters } from 'vuex'
import Sidebar from './'

export default {
  components: { Sidebar },
  computed: {
    ...mapGetters({
      activeItemTitle: 'docs/activeSectionTitle',
    }),
    items() {
      return this.$store.state.sidebar.items
    },
    title() {
      return this.$store.state.schemes?.currentScheme?.title
    },
  },
}
