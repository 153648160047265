//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    notification: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      timeoutRef: null,
    }
  },
  mounted() {
    this.timeoutRef = setTimeout(() => {
      this.close()
    }, 5000)
  },
  destroyed() {
    clearTimeout(this.timeoutRef)
  },
  methods: {
    close() {
      this.$store.commit('notifications/remove', this.notification.timestamp)
    },
  },
}
