var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('NuxtLink',{staticClass:"flex items-center justify-between ml-4 py-3 pl-0 pr-4 text-base leading-6 border-b dark:border-darkmode-gray-light border-gray-light capitalize",class:{
      'dark:text-darkmode-blue-700 text-blue-700': _vm.isActive,
      'text-navy-lighter dark:text-darkmode-navy-lighter': !_vm.isActive,
      'ml-0': _vm.level === 0,
    },attrs:{"to":_vm.item.path || ''}},[_c('span',[_vm._v(_vm._s(_vm.item.title))]),_vm._v(" "),(_vm.hasArrow)?_c('span',{staticClass:"text-xl leading-3",class:{
        'form3-chevron-up-icon': _vm.childrenAreVisible,
        'form3-chevron-down-icon': !_vm.childrenAreVisible,
      }}):_vm._e()]),_vm._v(" "),(_vm.childrenAreVisible)?_c('ul',_vm._l((_vm.item.toc),function(section){return _c('li',{key:((_vm.item.path) + "/" + (section.id)),staticClass:"ml-2"},[_c('MobileTocLinked',{attrs:{"linked-to":("" + (section.id))}},[_c('span',[_vm._v(_vm._s(section.text))])])],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }