import {
  updateListOfExpandedDocuments,
  mapPreloadedItems,
} from '@/utils/store-utils/docs-utils'

// used internally, no need to expose it in the store
export const _state = {
  commitActiveSectionURLsTimeout: 500,
  ignoreActiveDocumentUrlChangesTimeout: 500,
  addActiveSectionTimeoutRef: null,
  index: 0,
  activeSectionIndexHash: {},
  activeSectionUrlHash: {},
  forceScrollToUrlTimeoutRef: false,
}

export const state = () => ({
  items: [],
  activeSectionUrls: [],
  redirectToSectionUrl: null,
  // mapped by id
  preloadedDocuments: {},
  waitingOnDocumentExpansionFor: null,
  actualActiveSectionUrl: null,
  actualActiveSectionTitle: '',
  scrollIntoViewRequestCounter: {},
  ignoreActiveDocumentUrlChanges: false,
  allDocsWithAttributes: {},
})

export const mutations = {
  set(state, items) {
    if (process.client) {
      if (!state.items.length) {
        state.items = [...items]
      }
    } else {
      state.items = [...items]
    }
    mapPreloadedItems(state, items)

    updateListOfExpandedDocuments(state)
  },
  setDocsWithAttributes(state, docsWithAttributes) {
    state.allDocsWithAttributes = {
      ...state.allDocsWithAttributes,
      ...docsWithAttributes,
    }
  },
  insertLoadedDocumentOnClientAction(state, currentDocument) {
    state.waitingOnDocumentExpansionFor = currentDocument
    updateListOfExpandedDocuments(state)
  },
  resetAllDocuments(state) {
    state.items = []
    prevOrderedHash = {}
  },
  setActiveSectionURLs(state, newUrls) {
    state.activeSectionUrls = newUrls
  },
  setCurrentlyActiveSectionUrl(state, newUrl) {
    if (process.env.ALGOLIA_INDEX || state.ignoreActiveDocumentUrlChanges) {
      return
    }
    if (newUrl) {
      state.actualActiveSectionUrl = newUrl
    }
  },
  setCurrentlyActiveSectionTitle(state, title) {
    if (title) {
      state.actualActiveSectionTitle = title
    }
  },
  forceScrollToUrl(state) {
    if (!state.scrollIntoViewRequestCounter[state.actualActiveSectionUrl]) {
      state.scrollIntoViewRequestCounter = {
        [state.actualActiveSectionUrl]: 1,
      }
    } else {
      state.scrollIntoViewRequestCounter[state.actualActiveSectionUrl]++
    }
  },
  ignoreActiveDocumentUrlChanges(val) {
    state.ignoreActiveDocumentUrlChanges = val
  },
  resetActiveSectionUrl(state) {
    state.activeSectionUrls = []
    clearTimeout(_state.addActiveSectionTimeoutRef)
    _state.addActiveSectionTimeoutRef = null
    _state.activeSectionIndexHash = {}
    _state.activeSectionUrlHash = {}
    _state.index = 0
  },

  redirectToSectionUrl(state, url) {
    state.redirectToSectionUrl = url
  },
}

const reconstructActiveSectionUrls = ({ state, commit }) => {
  if (process.env.ALGOLIA_INDEX) {
    return
  }
  _state.addActiveSectionTimeoutRef = setTimeout(() => {
    _state.addActiveSectionTimeoutRef = null
    const activeSectionUrls = []
    let indexKeys = Object.keys(_state.activeSectionIndexHash)
    for (let i = 0; i < indexKeys.length; i++) {
      const indexKey = indexKeys[i]
      const url = _state.activeSectionIndexHash[indexKey]
      const urlHashContainsIndexedURL = _state.activeSectionUrlHash[url] >= 0
      if (urlHashContainsIndexedURL) {
        activeSectionUrls.push(url)
      } else {
        delete _state.activeSectionIndexHash[indexKey]
        indexKeys = Object.keys(_state.activeSectionIndexHash)
        i--
      }
    }
    commit('setActiveSectionURLs', activeSectionUrls)

    const activeSectionUrlValue = [...activeSectionUrls].sort((a, b) => {
      return orderedSectionUrlMap(state)[a] - orderedSectionUrlMap(state)[b]
    })[0]

    commit('setCurrentlyActiveSectionUrl', activeSectionUrlValue)

    let activeSectionTitle = ''

    if (state?.items?.length) {
      state.items.forEach(({ title, path, toc }) => {
        if (path === activeSectionUrlValue) {
          activeSectionTitle = title
        }

        if (toc.length) {
          toc.forEach(({ id, text }) => {
            if (`${path}/${id}` === activeSectionUrlValue) {
              activeSectionTitle = text
            }
          })
        }
      })
    }

    commit('setCurrentlyActiveSectionTitle', activeSectionTitle)
  }, _state.commitActiveSectionURLsTimeout)
}

let prevOrderedHash = {}
const orderedSections = (state) => {
  if (!prevOrderedHash.array?.length) {
    const result = state.items.reduce((arr, doc) => {
      return [
        ...arr,
        { url: doc.path, title: doc.title },
        ...doc.toc.map(({ id, text }) => ({
          url: `${doc.path}/${id}`,
          title: text,
        })),
      ]
    }, [])
    prevOrderedHash.array = result
    return result
  }

  return prevOrderedHash.array
}

const orderedSectionUrlMap = (state) => {
  if (!prevOrderedHash.sectionUrlMap) {
    const result = orderedSections(state).reduce((map, { url }, index) => {
      return {
        ...map,
        [url]: index,
      }
    }, {})

    prevOrderedHash.sectionUrlMap = result
    return result
  }

  return prevOrderedHash.sectionUrlMap
}

export const actions = {
  addActiveSectionUrl({ state, commit }, url) {
    _state.activeSectionIndexHash[_state.index] = url
    _state.activeSectionUrlHash[url] = _state.index
    _state.index++

    if (_state.addActiveSectionTimeoutRef) {
      return
    }
    reconstructActiveSectionUrls({ state, commit })
  },
  setDocsWithAttributes({ commit }, docsWithAttributes) {
    commit('setDocsWithAttributes', docsWithAttributes)
  },
  removeActiveSectionUrl({ state, commit }, url) {
    const index = _state.activeSectionUrlHash[url]
    delete _state.activeSectionIndexHash[index]
    delete _state.activeSectionUrlHash[url]

    if (_state.addActiveSectionTimeoutRef) {
      return
    }

    reconstructActiveSectionUrls({ state, commit })
  },
  resetActiveSectionUrl({ commit }, debugScheme) {
    commit('resetActiveSectionUrl')
  },
  redirectToSectionUrl({ commit }) {
    commit('redirectToSectionUrl')
  },
  setCurrentlyActiveSectionUrl({ commit }, url) {
    commit('setCurrentlyActiveSectionUrl', url)
  },
  forceScrollToUrl({ commit, dispatch }, url) {
    commit('forceScrollToUrl', url)
    clearTimeout(_state.forceScrollToUrlTimeoutRef)
    commit('ignoreActiveDocumentUrlChanges', true)
    _state.forceScrollToUrlTimeoutRef = setTimeout(() => {
      commit('ignoreActiveDocumentUrlChanges', false)
    }, _state.ignoreActiveDocumentUrlChangesTimeout)
  },
}

export const getters = {
  activeSectionUrl(state) {
    return state.actualActiveSectionUrl
  },
  activeSectionTitle(state) {
    return state.actualActiveSectionTitle
  },
  scrollIntoViewRequestCounter(state) {
    return state.scrollIntoViewRequestCounter
  },
}
