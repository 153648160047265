import { render, staticRenderFns } from "./api.vue?vue&type=template&id=1ae9fe2d&"
import script from "./api.vue?vue&type=script&lang=js&"
export * from "./api.vue?vue&type=script&lang=js&"
import style0 from "./api.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/Menu/index.vue').default,Notifications: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/global/Notifications/index.vue').default,CookiesBanner: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/Cookies/Banner/index.vue').default,CookiesModal: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/Cookies/Modal/index.vue').default})
