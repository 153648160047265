import get from 'lodash.get'

const getSidebarNavigationContent = ($context) =>
  $context.$content('sidebarNavigation').fetch()

const getAvailableSchemes = (sidebarNavigationContent) =>
  Object.entries(sidebarNavigationContent.api.schemes).map(
    ([name, contents]) => ({
      name,
      title: get(contents, '_meta.title', name),
    })
  )

export default async function ($context) {
  if ($context.route.params.routeUpdatedByScroll) {
    return
  }

  if ($context.store.state.schemes.availableSchemes.length) {
    return
  }

  const sidebarNavigationContent = await getSidebarNavigationContent($context)

  const availableSchemes = getAvailableSchemes(sidebarNavigationContent)
  $context.store.commit('schemes/setAvailableSchemes', availableSchemes)
}
