//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Menu from '~/components/Menu'
import ConnectedSidebar from '~/components/Sidebar/Desktop/ConnectedSidebar'
import ConnectedMobileSidebar from '~/components/Sidebar/Mobile/ConnectedMobileSidebar'
import setInitialTheme from '~/mixins/setInitialTheme'

export default {
  components: {
    Menu,
    ConnectedSidebar,
    ConnectedMobileSidebar,
  },
  mixins: [setInitialTheme],
  computed: {
    isSearchModalOpened() {
      return this.$store.state.search.isSearchModalOpened
    },
  },
  watch: {
    isSearchModalOpened() {
      if (!this.isSearchModalOpened) {
        this.$refs.wrapper.focus()
      }
    },
  },
  mounted() {
    this.setViewHeight()
    window.addEventListener('resize', this.setViewHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.setViewHeight)
  },
  methods: {
    setViewHeight() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    openSearchModal(...args) {
      this.$store.commit('search/openSearchModal')
    },
  },
}
