//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sidebarMixin from '~/mixins/sidebarMixin'

export default {
  name: 'SidebarDir',
  components: {
    SidebarElement: () => import('../SidebarElement'),
  },
  mixins: [sidebarMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isExpanded: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    level: {
      type: Number,
      required: false,
      default: () => 0,
    },
    isParentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      childrenVisible: this.isExpanded,
    }
  },

  computed: {
    tabIndex() {
      if (this.level === 0) return 0

      return this.isParentActive ? 0 : -1
    },
    isActive() {
      return this.isExpanded
    },
  },

  watch: {
    isExpanded(v) {
      this.childrenVisible = v
    },
  },

  methods: {},
}
