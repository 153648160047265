//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    isOpened: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
