//
//
//

import ErrorPage from '~/components/ErrorPage'

export default {
  components: { ErrorPage },
  props: {
    error: {
      type: Object,
      default: () => {
        return { statusCode: 404 }
      },
    },
  },
  head() {
    return {
      title: 'Form3 API Docs',
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex' }],
    }
  },
}
