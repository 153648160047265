export default {
  computed: {
    variants() {
      // for future per level variants
      const levels = {
        0: {
          bg: 'blue-200',
          chevron: 'blue-300',
        },
      }
      return levels[0]
    },

    active() {
      return this.isActive
    },

    textClass() {
      const common = `tracking-[0.28px] capitalize text-sm flex items-center`
      const active = `text-blue-700 dark:text-darkmode-blue-700 ${common}`
      const inactive = `text-navy-dark dark:text-darkmode-navy-light ${common}`

      return this.active ? active : inactive
    },

    backgroundClass() {
      const hover = `hover:bg-gray-100 dark:hover:bg-darkmode-gray-100`
      const active = `bg-blue-200 dark:bg-darkmode-${this.variants.bg} ${hover}`
      const inactive = `bg-transparent ${hover}`

      return this.active ? active : inactive
    },

    chevronClass() {
      const bgClass = `bg-blue-300 dark:bg-darkmode-${this.variants.chevron}`
      const chevBg = this.isActive ? `${bgClass}` : ''

      const active = `form3-chevron-down-icon ${chevBg}`
      const inactive = `form3-chevron-right-icon ${chevBg}`

      return this.childrenVisible ? active : inactive
    },
  },
}
