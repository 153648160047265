//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    DesktopMenuItemExternal: () => import('./DesktopMenuItemExternal'),
    DesktopMenuItemInternal: () => import('./DesktopMenuItemInternal'),
    DesktopMenuItemInternalWithChildren: () =>
      import('./DesktopMenuItemInternalWithChildren'),
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
  },
}
