//
//
//
//
//
//
//
//
//
//
//
//

export const STATES = {
  default: '',
  loading: 'loading',
  error: 'error',
  success: 'success',
}

const iconMap = {
  [STATES.default]: '',
  [STATES.loading]: 'form3-loading-icon',
  [STATES.error]: 'form3-close-circle-icon',
  [STATES.success]: 'form3-check-circle-icon',
}

export default {
  props: {
    state: {
      type: String,
      required: false,
      default: STATES.default,
    },
    theme: {
      type: String,
      required: false,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    icon() {
      return iconMap[this.state]
    },
    shouldSpinIcon() {
      return this.state === STATES.loading
    },
    alwaysShowIcon() {
      return this.state !== STATES.default
    },
  },
}
