//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    MobileMenuItem: () => import('./MobileMenuItem'),
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  methods: {
    toggleExpandedState() {
      this.isExpanded = !this.isExpanded
    },
  },
}
