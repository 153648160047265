export const state = () => ({
  isCookieBannerVisible: false,
  isCookieModalVisible: false,
})

export const mutations = {
  hideCookieBanner(state) {
    state.isCookieBannerVisible = false
  },
  showCookieBanner(state) {
    state.isCookieBannerVisible = true
  },
  hideCookieModal(state) {
    state.isCookieModalVisible = false
  },
  showCookieModal(state) {
    state.isCookieModalVisible = true
  },
}
