//
//
//
//
//
//
//
//
//
//

import {
  defaultCookieValue,
  deleteAllCookies,
  saveUserCookieSettings,
} from '~/utils/cookie'

export default {
  name: 'CookiesRejectButton',
  methods: {
    clickHandler() {
      deleteAllCookies()
      saveUserCookieSettings(defaultCookieValue)

      // refresh the page in order for the tracking scripts removal to take effect
      window.location.reload()
    },
  },
}
