//
//
//
//
//
//
//
//
//

export const colors = {
  red: 'red',
  required: 'red',
  orange: 'orange',
  always: 'orange',
  blue: 'blue',
  optional: 'blue',
  indigo: 'indigo',
  conditional: 'indigo',
  purple: 'purple',
  sometimes: 'purple',
  gray: 'gray',
  black: 'black',
  sepa: 'sepa',
  sepai: 'sepai',
  fps: 'fps',
  bacs: 'bacs',
  indirect: 'indirect',
  tutorial: 'tutorial',
  full: 'relationship-full',
  link: 'relationship-link',
  short: 'relationship-short',
}

export default {
  props: {
    color: {
      required: false,
      type: String,
      default: colors.gray,
    },
  },
  computed: {
    className() {
      switch (this.color) {
        case colors.red:
        case colors.required:
          return 'dark:text-darkmode-white text-white bg-required dark:bg-darkmode-required'
        case colors.orange:
        case colors.always:
          return 'dark:text-darkmode-white text-white dark:bg-darkmode-always bg-always'
        case colors.blue:
        case colors.optional:
          return 'dark:text-darkmode-white text-white dark:bg-darkmode-optional bg-optional'
        case colors.indigo:
        case colors.conditional:
          return 'dark:text-darkmode-white text-white bg-conditional dark:bg-darkmode-conditional'
        case colors.purple:
        case colors.sometimes:
          return 'dark:text-darkmode-white text-white dark:bg-darkmode-sometimes bg-sometimes'
        case colors.gray:
          return 'dark:text-darkmode-dark-secondary text-dark-secondary border border-gray-light dark:border-darkmode-gray-light'
        case colors.black:
          return 'dark:text-darkmode-white text-white bg-navy-lighter dark:bg-darkmode-navy-lighter'
        case colors.sepa:
          return 'dark:text-darkmode-white text-white bg-sepa dark:bg-darkmode-sepa'
        case colors.sepai:
          return 'dark:text-darkmode-white text-white bg-sepai dark:bg-darkmode-sepai'
        case colors.fps:
          return 'dark:text-darkmode-white text-white bg-fps dark:bg-darkmode-fps'
        case colors.bacs:
          return 'dark:text-darkmode-white text-white bg-bacs dark:bg-darkmode-bacs'
        case colors.indirect:
          return 'dark:text-darkmode-white text-white bg-indirect dark:bg-darkmode-indirect'
        case colors.tutorial:
          return 'dark:text-darkmode-white text-white bg-tutorial dark:bg-darkmode-tutorial'
        case colors.link:
          return 'dark:text-darkmode-blue-700 text-blue-700 border border-blue-300 dark:border-darkmode-blue-300'
        case colors.short:
          return 'dark:text-darkmode-orange text-orange border border-orange-300 dark:border-darkmode-orange-300'
        case colors.full:
          return 'dark:text-darkmode-teal-600 text-teal-600 border border-teal-300 dark:border-darkmode-teal-300'
      }

      return ''
    },
  },
}
