export const mapPreloadedItems = (state, items) => {
  items.forEach((item) => {
    if (
      !state.preloadedDocuments[item.path] ||
      (!state.preloadedDocuments[item.path].body && item.body)
    ) {
      state.preloadedDocuments[item.path] = item
    }
  })
}

export const updateListOfExpandedDocuments = (state) => {
  if (!state.waitingOnDocumentExpansionFor) {
    return
  }
  const documentWithBody =
    state.preloadedDocuments[state.waitingOnDocumentExpansionFor.path] &&
    state.preloadedDocuments[state.waitingOnDocumentExpansionFor.path].body &&
    state.preloadedDocuments[state.waitingOnDocumentExpansionFor.path]
  if (documentWithBody && !state.waitingOnDocumentExpansionFor.body) {
    const mappedItems = state.items.map((item) => {
      if (item.path !== state.waitingOnDocumentExpansionFor.path) {
        return item
      }
      documentWithBody.similarDocuments =
        state.waitingOnDocumentExpansionFor.similarDocuments
      return documentWithBody
    })
    state.waitingOnDocumentExpansionFor = null
    state.items = [...mappedItems]
  }
}
