var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"hidden",attrs:{"id":_vm.randomId,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,_vm.value)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}},'input',_vm.$attrs,false),_vm.listeners)),_vm._v(" "),(_vm.label)?_c('label',{staticClass:"flex items-center gap-x-2 text-base group text-black dark:text-darkmode-blue-700",class:{
      'cursor-pointer': !_vm.disabled,
      'cursor-not-allowed': _vm.disabled,
      'font-bold': !_vm.small,
      'text-sm': _vm.small,
    },attrs:{"for":_vm.randomId}},[_c('span',{class:{
        'form3-checkbox-checked-icon': _vm.checked,
        'form3-checkbox-unchecked-icon text-navy-lighter dark:text-darkmode-navy-ligther': !_vm.checked,
        'text-gray dark:text-darkmode-gray': _vm.disabled,
        'text-2xl': _vm.small,
        'text-3xl': !_vm.small,
      }}),_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }