//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import matchUrlFromStore from '@/components/global/mixins/matchUrlFromStore'
import autoScrollToMatchedUrl, {
  SCROLL_STRATEGY,
} from '@/components/global/mixins/autoScrollToMatchedUrl'

export default {
  name: 'TocLinked',
  mixins: [matchUrlFromStore, autoScrollToMatchedUrl],
  data() {
    return {
      scrollStrategy: SCROLL_STRATEGY.scrollIfNeeded,
    }
  },
}
