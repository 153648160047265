import get from 'lodash.get'
import { removeLastSlashFromUrl } from '@/utils'

export const isActiveDocument = (doc, url) => {
  const parsedUrl = removeLastSlashFromUrl(url)
  return (
    parsedUrl === doc.path ||
    parsedUrl.split('/').slice(0, -1).join('/') === doc.path
  )
}

export const getActiveDocument = ($context) => {
  const docs = $context.store.state.docs.items
  const routePath = decodeURIComponent($context.route.path)

  return docs.find((doc) => isActiveDocument(doc, routePath))
}

export const isMarkdownFile = (filePath = '') => /\.md$/.test(filePath)

export const stripFileExtension = (filePath = '') =>
  filePath.replace(/\.\w*$/, '')

export const getDirectoryMeta = (dirPath, flatItems = {}) => {
  const metaTitle = get(flatItems, `${dirPath}/_meta/title`)
  const metaTag = get(flatItems, `${dirPath}/_meta/tag`, undefined)

  return {
    title: metaTitle || dirPath.split('/').pop(),
    tag: metaTag,
  }
}

export const reconstructMenuTreeFromFlatMap = (flatFileMap, pathRootString) => {
  if (!pathRootString) {
    throw new Error('pathRootString must be set')
  }

  return flatFileMap.reduce((paths, next) => {
    const docPath = next.path
    const isChild = docPath.includes('/')

    if (isChild) {
      const item = paths.find(
        (e) =>
          e.path === `/${pathRootString}/${docPath.replace(/\/[^/]+$/g, '')}`
      )
      if (item) {
        next.path = `/${pathRootString}/${next.path}`
        next.path = next.path.replace(`${item.path}/`, '')
        item.children = [...item.children, next]
      }
    } else {
      next.path = `/${pathRootString}/${next.path}`
      paths.push(next)
    }

    return paths
  }, [])
}

export const fetchDocument = async ({
  path,
  $content,
  fetchFullDocument = false,
}) => {
  const without = fetchFullDocument ? [] : ['body']

  let [doc] = await $content('', { deep: true })
    .where({ path })
    .without(without)
    .fetch()

  if (doc.disable_show_more) {
    ;[doc] = await $content('', { deep: true }).where({ path }).fetch()
  }

  return doc
}

export const processDirectory = async ({
  files,
  path,
  rootDir,
  $content,
  flatItems,
}) => {
  const fetchFullDocument = process.env.ALGOLIA_INDEX
  const children = await Promise.all(
    files.filter(isMarkdownFile).map((filePath) =>
      fetchDocument({
        $content,
        path: `${rootDir}/${path}/${stripFileExtension(filePath)}`,
        fetchFullDocument,
      })
    )
  )

  return {
    children,
    path,
    ...getDirectoryMeta(path, flatItems),
  }
}

export const getCanonicalUrl = (document = {}) => {
  // @TODO
  // while google reads relative canonical urls (<link rel="canonical" href="./relative/path" />)
  // other engines might only take into consideration absolute paths (<link rel="canonical" href="https://api-docs.form3.tech/relative/path" />)
  // this should be refactored when we have a better understanding of the deployment structure/pipeline
  const domain = ''

  return `${domain}${document.path}`
}

export const getHeadData = (metadata = {}, prefix) => {
  const meta = []
  const link = []

  if (metadata.title) {
    const title = prefix ? `${prefix}${metadata.title}` : metadata.title

    meta.push({
      hid: 'og:title',
      property: 'og:title',
      content: title,
    })
    meta.push({
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title,
    })
  }

  if (metadata.description) {
    meta.push({
      hid: 'description',
      name: 'description',
      content: metadata.description,
    })
    meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: metadata.description,
    })
    meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: metadata.description,
    })
  }

  if (metadata.image) {
    meta.push({
      hid: 'og:image',
      property: 'og:image',
      content: metadata.image,
    })
    meta.push({
      hid: 'twitter:image',
      name: 'twitter:image',
      content: metadata.image,
    })
  }

  if (metadata.canonicalUrl) {
    meta.push({
      hid: 'og:url',
      property: 'og:url',
      content: metadata.canonicalUrl,
    })

    link.push({
      hid: 'link:canonical',
      rel: 'canonical',
      href: metadata.canonicalUrl,
    })
  }

  return {
    title: metadata.title,
    meta,
    link,
  }
}

const getItemsAtKey = (object, key, defaultValue) => {
  return object[key] || defaultValue
}

export const removeTocItemsTextAfterComma = (entries) => {
  if (!entries || !Array.isArray(entries)) {
    return []
  }
  return entries.map((entry) => {
    return {
      ...entry,
      children: getItemsAtKey(entry, 'children', []).map((child) => {
        return {
          ...child,
          toc: getItemsAtKey(child, 'toc', []).map((tocItem) => {
            return {
              ...tocItem,
              text: getItemsAtKey(tocItem, 'text', '').split(',')[0],
            }
          }),
        }
      }),
    }
  })
}
