import { getActiveDocument } from '../utils/pages-utils'

const fetchFullDocument = async ($context, doc) => {
  const document = await $context
    .$content('', { deep: true })
    .where({ path: doc.path })
    .fetch()
    .then(([doc]) => doc)

  const path = document.path
  const scheme = path.replace(/^\/api\/schemes\/(.*?)\/.*$/, '$1')
  const documentPath = path.replace(/^\/api\/schemes\/.*?\/(.*)\/.*?$/, '$1')
  const sectionSlug = path.replace(/^.*\/(.*?)$/, '$1')
  const availableSchemes = $context.store.state.schemes.availableSchemes

  const results = await $context
    .$content('', { deep: true })
    .only('path')
    .where({
      path: { $contains: documentPath },
      slug: { $eq: sectionSlug },
    })
    .fetch()

  const similarDocuments = results
    .filter(
      (r) =>
        r.path.indexOf('/api/schemes/') === 0 &&
        r.path.indexOf(`/api/schemes/${scheme}/`) !== 0
    )
    .map((result) => {
      const name = result.path.replace(/^\/api\/schemes\/(.*?)\/.*$/, '$1')
      const currentScheme = availableSchemes.find(
        (scheme) => scheme.name === name
      )

      return {
        path: result.path,
        title: currentScheme.title,
      }
    })

  document.similarDocuments = similarDocuments
  return document
}

const updateActiveDocumentInDocs = ($context, activeDocument) => {
  if (!activeDocument) {
    return
  }

  const docs = $context.store.state.docs.items

  const updatedDocs = docs.map((doc) => {
    if (doc.path === activeDocument.path) {
      return activeDocument
    }
    return doc
  })

  if (process.client) {
    $context.store.commit('docs/insertLoadedDocumentOnClientAction', {
      path: activeDocument.path,
      similarDocuments: activeDocument.similarDocuments,
    })
  }

  $context.store.commit('docs/set', updatedDocs)
}
export default async function ($context) {
  if ($context.route.params.routeUpdatedByScroll) {
    return
  }

  const activeDocument = getActiveDocument($context)

  if (!activeDocument) {
    // don't show the 404 page if we manually redirected to the scheme
    if (
      $context.route.path.startsWith('/api/schemes') &&
      $context.route.path !==
        `/api/schemes/${$context.store.state.schemes.currentScheme.name}/`
    ) {
      // eslint-disable-next-line
      console.error('invalid document, redirecting to 404 page')

      $context.error({
        status: '404',
      })
    }
    return
  }

  const expandedActiveDocument = await fetchFullDocument(
    $context,
    activeDocument
  )

  if (!expandedActiveDocument) {
    // eslint-disable-next-line
    console.error('invalid expanded document... redirecting to 404 page')
    $context.error({
      status: '404',
    })
    return
  }

  updateActiveDocumentInDocs($context, expandedActiveDocument)
}
