//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import matchUrlFromStore from '@/components/global/mixins/matchUrlFromStore'
import autoScrollToMatchedUrl from '@/components/global/mixins/autoScrollToMatchedUrl'
import MobileSidebarDir from './MobileSidebarDir'

export default {
  components: {
    MobileSidebarDir,
  },
  mixins: [matchUrlFromStore, autoScrollToMatchedUrl],
  props: MobileSidebarDir.props,
}
