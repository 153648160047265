import flattenDeep from 'lodash.flattendeep'
import get from 'lodash.get'
import { flatten } from 'flat'
import {
  isActiveDocument,
  reconstructMenuTreeFromFlatMap,
  processDirectory,
} from '../utils/pages-utils'

export const isValidScheme = ($context) => {
  const currentScheme = get($context, 'store.state.schemes.currentScheme', {})
  const availableSchemes = get(
    $context,
    'store.state.schemes.availableSchemes',
    []
  )

  if (availableSchemes.find((scheme) => scheme.name === currentScheme.name)) {
    return true
  }

  return false
}

export const isMetaPath = (path = '') => /(^|\/)_meta\//.test(path)

const getSidebarNavigationContent = ($context) =>
  $context.$content('sidebarNavigation').fetch()

const getDocuments = async (
  $context,
  { currentScheme, sidebarNavigationContent }
) => {
  const pathRootString = `api/schemes/${currentScheme.name}`

  const { api } = sidebarNavigationContent

  const schemes = Object.entries(api.schemes).reduce(
    (result, [schemeName, children]) => {
      const { _meta, ...schemeContent } = children
      return { ...result, [schemeName]: schemeContent }
    },
    {}
  )

  const scheme = schemes[currentScheme.name]

  const flatItems = flatten(scheme, {
    delimiter: '/',
    safe: true,
  })

  const flatFileMap = await Promise.all(
    Object.entries(flatItems)
      .filter(([path]) => !isMetaPath(path))
      .map(([path, children]) =>
        processDirectory({
          $context,
          $content: $context.$content,
          files: children,
          path: path.replace(/\/children$/, ''),
          rootDir: `/${pathRootString}`,
          flatItems,
        })
      )
  )

  const menuTree = reconstructMenuTreeFromFlatMap(flatFileMap, pathRootString)
  $context.store.commit('sidebar/set', menuTree)

  const getDocsFromMenuTree = (menuTree) => {
    if (menuTree.children) {
      return menuTree.children.map(getDocsFromMenuTree)
    }

    return [menuTree]
  }

  return flattenDeep(menuTree.map(getDocsFromMenuTree))
}

// fallback, in case URL is not pointing to a document
const getActiveDocument = ($context, docs) => {
  const routePath = decodeURIComponent($context.route.path)
  const activeDocumentWithoutBody = docs.find((doc) =>
    isActiveDocument(doc, routePath)
  )

  return activeDocumentWithoutBody
}

const getRedirectToSectionUrl = (docs, activeDocument) => {
  if (!activeDocument) {
    return `${docs[0].path}`
  }
  return null
}

export default async function ($context) {
  if ($context.route.params.routeUpdatedByScroll) {
    return
  }

  const currentSchemeName = $context.route.params.scheme
  if (
    $context.store.state.schemes.currentScheme &&
    $context.store.state.schemes.currentScheme.name === currentSchemeName
  ) {
    return
  }

  const currentScheme = $context.store.state.schemes.availableSchemes.find(
    (scheme) => scheme.name === currentSchemeName
  )

  if (process.client) {
    $context.store.commit('docs/resetAllDocuments')
  }
  $context.store.commit('schemes/setCurrentScheme', currentScheme)

  if (process.client) {
    $context.store.commit('docs/resetActiveSectionUrl')
  }

  const sidebarNavigationContent = await getSidebarNavigationContent($context)

  if (!isValidScheme($context)) {
    // eslint-disable-next-line
    console.error('invalid scheme... redirecting to 404 page')
    $context.error({
      status: '404',
    })
    return
  }

  const docs = await getDocuments($context, {
    currentScheme,
    sidebarNavigationContent,
  })
  $context.store.commit('docs/set', docs)

  const activeDocument = await getActiveDocument($context, docs)

  const redirectToSectionUrl = getRedirectToSectionUrl(docs, activeDocument)
  $context.store.commit('docs/redirectToSectionUrl', redirectToSectionUrl)
}
