//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MobileMenuItem from './MobileMenuItem.vue'

export default {
  components: { MobileMenuItem },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  watch: {
    '$route.fullPath'() {
      this.hideMenu()
    },
  },
  methods: {
    showMenu() {
      this.isOpened = true
    },
    hideMenu() {
      this.isOpened = false
    },
  },
}
