//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import matchUrlFromStore from '@/components/global/mixins/matchUrlFromStore'
import autoScrollToMatchedUrl from '@/components/global/mixins/autoScrollToMatchedUrl'
import SidebarDir from './'

export default {
  name: 'SidebarDirLinked',
  components: {
    SidebarDir,
  },
  mixins: [matchUrlFromStore, autoScrollToMatchedUrl],
  props: SidebarDir.props,
}
