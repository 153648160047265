//
//
//
//
//
//
//
//
//
//
//

import MobileSidebarElement from './MobileSidebarElement.vue'
import ActionSheet from '~/components/ActionSheet'

export default {
  name: 'MobileSidebar',
  components: {
    MobileSidebarElement,
    ActionSheet,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: 'API Docs index',
    },
    isOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
  },
}
