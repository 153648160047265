//
//
//
//
//
//
//
//
//
//
//
//

import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import routes from './routes'

export default {
  components: {
    DesktopMenu,
    MobileMenu,
  },
  props: {
    pageHasSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageHasMaxWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    availableSchemes() {
      return this.$store.state.schemes.availableSchemes || []
    },
    items() {
      return routes(this.$route.name, this.availableSchemes)
    },
  },
}
