import { render, staticRenderFns } from "./legal.vue?vue&type=template&id=5e134041&"
import script from "./legal.vue?vue&type=script&lang=js&"
export * from "./legal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/home/runner/work/api-docs-frontend/api-docs-frontend/components/Menu/index.vue').default})
