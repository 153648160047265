//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    isSearchModalOpened() {
      return this.$store.state.search.isSearchModalOpened
    },
  },
  mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        this.handleFocus()
      }
    })
  },
  methods: {
    handleFocus() {
      this.$refs.input.blur()
      this.$store.commit('search/openSearchModal')
    },
  },
}
