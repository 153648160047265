var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-mulish dark:text-darkmode-navy-dark text-navy-dark"},[(_vm.item.url && !_vm.item.isExternal)?_c('nuxt-link',{staticClass:"block",class:{
      'text-2xl leading-7 font-semibold': _vm.item.isTopLevel,
      'text-lg': !_vm.item.isTopLevel,
    },attrs:{"to":_vm.item.url,"title":_vm.item.title}},[_vm._v("\n    "+_vm._s(_vm.item.title)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.item.url && _vm.item.isExternal)?_c('a',{staticClass:"block",class:{
      'text-2xl leading-7 font-semibold': _vm.item.isTopLevel,
      'text-lg': !_vm.item.isTopLevel,
    },attrs:{"href":_vm.item.url,"title":_vm.item.title,"target":"_blank"}},[_vm._v("\n    "+_vm._s(_vm.item.title)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.item.children)?_c('span',{staticClass:"flex justify-between cursor-pointer",class:{ 'text-2xl font-semibold': _vm.item.isTopLevel },on:{"click":_vm.toggleExpandedState}},[_vm._v("\n    "+_vm._s(_vm.item.title)+"\n    "),_c('span',{staticClass:"inline-block transition-transform transform form3-chevron-down-icon",class:{
        '-rotate-180': _vm.isExpanded,
        'text-2xl': _vm.item.isTopLevel,
      }})]):_vm._e(),_vm._v(" "),(_vm.item.children && _vm.isExpanded)?_c('ul',{staticClass:"flex flex-col gap-7 pt-7"},_vm._l((_vm.item.children),function(child){return _c('MobileMenuItem',{key:child.title,attrs:{"item":child}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }